<template>
  <section>
    <div class="row">
      <div class="col-md-12">
        <div class="generic-wizard-heading">Select Your Board</div>
      </div>
    </div>
    <skeleton-loader id="boardLoader" :count="3" :showEmptyTemplate="true" :defaultLoadingProp="true">
      <template v-slot:default="{ isLoading }">
        <div class="generic-layout-cntr" v-if="boardList && selectedClassBoard">
          <div
            class="board-box"
            
            v-for="(board, index) in boardList"
            :key="index"
            @click="
              updateSelectedClassBoard(board);
              UpdateNextWizard();
            "
          >
            <div class="course-icon">
              <img height="51" :src="board.Icon" />
            </div>
            <div class="board-box-head">
              {{ board.Name }}
            </div>
          </div>
        </div>
        <div v-else-if="isLoading"  class="text-center my-5">No board found.</div>
      </template>
    </skeleton-loader>
    <!-- <skeleton-loader id="boardLoader" :count="3"></skeleton-loader> -->
    <div class="d-flex justify-content-center">
      <button @click="goBack()" class="btn secondary-btn" :disabled="isNextButtonDisabled">Go back</button>
    </div>

    <!-- <p>{{ selectedCourseInfo }}</p> -->
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { board as boardStoreKeys, course as courseStoreKeys, classes as classesStoreKeys } from "../../../store/keys";
import { appConstants } from "../../../common/constants";
import breadcrumb from "../breadcrumb.vue";

export default {
  components: {
    breadcrumb,
  },
  data() {
    return {
      isNextButtonDisabled: true,
      boardList: [],
      selectedClassBoard: null,
    };
  },
  // Client-side only
  mounted() {
    this.getClassBoards();
  },
  destroyed() {
    // this.resetBoards();
  },
  watch: {
    $route(to, from) {
      if (process.env.VUE_ENV === "client") {
        this.getClassBoards();
      }
    },
  },
  computed: {
    ...mapGetters(boardStoreKeys.namespace, boardStoreKeys.getters),
    ...mapGetters(courseStoreKeys.namespace, courseStoreKeys.getters),
    ...mapGetters(classesStoreKeys.namespace, classesStoreKeys.getters),
  },
  // Server-side only
  serverPrefetch() {},
  methods: {
    ...mapActions(boardStoreKeys.namespace, boardStoreKeys.actions),
    ...mapActions(courseStoreKeys.namespace, courseStoreKeys.actions),
    goBack() {
      let name = this.$route.name === "FreeTrialExam" || this.$route.name === "FreeTrialClass" ? "free-trial" : "category";
      this.$router.push({ name: name }).catch(() => {});
      // this.$router.push(`/${name}`);
    },
    courseSubscriptionType() {
      let subscriptionType = this.$route.name === "FreeTrialClass" ? appConstants.enums.subscriptionType.Free : appConstants.enums.subscriptionType.All;
      return subscriptionType;
    },
    getClassBoards() {
      this.isNextButtonDisabled = true;
      return this.get_ClassBoards({
        data: {
          AcademicProgramId: this.selectedProgram.id,
          SubscriptionType: this.courseSubscriptionType(),
        },
        config: {
          loader: {
            show: true,
            id: "boardLoader",
          },
        },
      })
        .then((response) => {
          this.boardList = response.Boards;
          this.updateSelectedClassBoard(
            this.selectedCourseInfo.board.id === 0
              ? response.Boards[0]
              : response.Boards.find((board) => {
                  return board.BoardId === this.selectedCourseInfo.board.id;
                })
          );

          this.update_SelectedClassBoard({
            data: {
              id: 0,
              name: "",
            },
          });

          this.update_SelectedCourseGroup({
            data: {
              id: 0,
              name: "",
            },
          });
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
    updateSelectedClassBoard(board) {
      this.selectedClassBoard = board;
      this.isNextButtonDisabled = false;
    },
    UpdateNextWizard() {
      this.update_SelectedClassBoard({
        data: {
          id: this.selectedClassBoard.BoardId,
          name: this.selectedClassBoard.Name,
        },
        config: {
          loader: {
            show: false,
          },
        },
      })
        .then((response) => {
          this.$emit("onUpdateWizard", "wizard-step3");
        })
        .catch((ex) => {
          this.$alertError(ex.message);
        });
    },
  },
};
</script>
